import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyKdramaChat } from '@pixels/universal/model/apps/app-types';
import { KdramaChatProjectMeta } from '@pixels/universal/model/apps/project-meta/kdrama-chat-project-meta';

@Injectable({ providedIn: 'root' })
export class KdramaChatAppInfo extends AppInfo {
  public readonly appType = AppKeyKdramaChat;
  public readonly serviceName = 'KDrama Chat';
  public readonly appStoreUrl = signal(KdramaChatProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${KdramaChatProjectMeta.appId}`);

  public override readonly enabledBoardPostsDefaultNation: boolean = false;
  public override readonly enabledOpenChatsPostDefaultNation: boolean = false;
  public override readonly enabledWaitingRoomsDefaultNation: boolean = false;
  public override readonly admobBannerVideoChatAppId = (inject(Platform).is('ios') ? KdramaChatProjectMeta.ios?.admobBannerVideoChatAppId : KdramaChatProjectMeta.android?.admobBannerVideoChatAppId) ?? '';
  public override readonly loginTitle = 'KDrama Chat';
  public override readonly loginDescription = _('@K_드라마_팬들_모여_친구를_만들어보아요@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/kdramachat-app-icon.svg';
  public override readonly bannerPlaceHolder = _('@K_드라마_채팅_글로벌_팬들과_함께_대화해요@');
  public override readonly adminMenus = ONE_ON_ONE_ADMIN_MENUS;
  public override readonly enabledWaitingRoomInitGenderFilter: boolean = false;
}
