import { AppKeyKdramaChat } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'kdrama-chat-client';
const PROD_WEB_PUSH_KEY = 'BKC9zKI50xqy6nbvGBRb52867NOM6c7XuXp-gNwZFm4TVcCHbvZDoU1qT9r7QuMDjXjoc7ziwNTiGVtzUSsUfZ8';
const DEV_WEB_PUSH_KEY = 'BHN7JAzU5L5CVvkj0Sul9R2-ZMdxYGDUDyEuU0NEDhpD9Yqjfb4EeuN8MKeAob_iXphVMhkASrNaPzkbCCO_EOw';
const PROD_AUTH_CALLBACK_URL = 'https://pixels-kdrama-chat.firebaseapp.com/__/auth/handler';
const DEV_AUTH_CALLBACK_URL = 'https://pixels-kdrama-chat-dev.firebaseapp.com/__/auth/handler';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyCbNXt6jdgO70_TuJoqrAGwHqV_u47II_o',
  authDomain: 'pixels-kdrama-chat.firebaseapp.com',
  projectId: 'pixels-kdrama-chat',
  storageBucket: 'pixels-kdrama-chat.appspot.com',
  messagingSenderId: '361326289967',
  appId: '1:361326289967:web:4563ef01a0ceda5ab652da',
  measurementId: 'G-RFXQZGESBF'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyDTUycev0uNm4zrt8Akn88XmpJwo4H2gDo',
  authDomain: 'pixels-kdrama-chat-dev.firebaseapp.com',
  projectId: 'pixels-kdrama-chat-dev',
  storageBucket: 'pixels-kdrama-chat-dev.appspot.com',
  messagingSenderId: '454929514873',
  appId: '1:454929514873:web:a2638fc07614f824b5efa8',
  measurementId: 'G-FQHKGTQG6P'
};
const PROD_TALK_PLUS_APP_ID = 'c7749e33-4919-4e17-be8d-b31588a0365b';
const DEV_TALK_PLUS_APP_ID = '618a8b13-f9f9-460e-abea-aaf7728569e4';

export const KdramaChatProjectMeta: ProjectMeta = {
  id: AppKeyKdramaChat,
  mainPrimaryColor: '#8C1ED4',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8114,
  apiName: 'kdrama-chat-api',
  apiPort: 5014,
  appId: 'io.pixelteam.kdramachat',
  appStoreUrl: 'https://apps.apple.com/app/id6502038210',
  appName: 'KDrama Chat',
  appNameEn: 'KDrama Chat',
  appSchemeName: 'KDrama Chat',
  testTargetUrl: 'https://test-kdramachat.pixelteam.io',
  devTargetUrl: 'https://dev-kdramachat.pixelteam.io',
  prodApi: 'https://kdramachat-api.pixelteam.io',
  devApi: 'https://dev-kdramachat-api.pixelteam.io',
  firebaseId: 'pixels-kdrama-chat',
  iosAppId: '1:198703238152:ios:0e61264462275a444815bd',
  androidAppId: '1:198703238152:android:82ae8ea509379a464815bd',
  testHostname: 'test-kdramachat',
  clientSentryDsn: 'https://a3a3185039f21d49904856db2c00fa6c@o190729.ingest.us.sentry.io/4507165556146176',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/11294/deploy',
    targets: ['prod-01'],
    port: 8600,
    androidAppId: '1:361326289967:android:5bab392d83157bbbb652da',
    iosAppId: '1:361326289967:ios:776390eb730ad04bb652da',
    iosEncodingAppId: 'app-1-361326289967-ios-776390eb730ad04bb652da',
    kakaoAppKey: 'ae3d5b61a76913ca769ec122693ba2ed',
    webAppId: '1:361326289967:web:4563ef01a0ceda5ab652da',
    googleReversedId: 'com.googleusercontent.apps.361326289967-5uul2sf6g6fs11sa2opedcs4dqf6ar5m',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://kdramachat-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11281/deploy',
    targets: ['dev-01'],
    port: 8601,
    androidAppId: '1:454929514873:android:1ef72e4cc75adb25b5efa8',
    iosAppId: '1:454929514873:ios:32d06e8ac1ec5920b5efa8',
    iosEncodingAppId: 'app-1-454929514873-ios-32d06e8ac1ec5920b5efa8',
    webAppId: '1:454929514873:web:a2638fc07614f824b5efa8',
    kakaoAppKey: '2cc3c98e13cef43143732a1e1d22533d',
    googleReversedId: 'com.googleusercontent.apps.454929514873-3nikrbj059ci9skc6q6sruc738ge2d4c',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-kdramachat-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:454929514873:android:231c8b387ac1c22fb5efa8',
    iosAppId: '1:454929514873:ios:74732fe8fac4e6a8b5efa8',
    iosEncodingAppId: 'app-1-454929514873-ios-74732fe8fac4e6a8b5efa8',
    kakaoAppKey: '2cc3c98e13cef43143732a1e1d22533d',
    googleReversedId: 'com.googleusercontent.apps.454929514873-nargl7eld170e8flm9iu66c8r0lbq00r',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-kdramachat-api.pixelteam.io',
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~6490514745',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/4633054892',
  },
  ios: {
    admobAppId: 'ca-app-pub-4000507444081320~3894688294',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/1924583770',
  },
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 KDrama Chat에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach photos taken with your camera to messages or register them as your profile picture',
  NSPhotoLibraryAddUsageDescriptionEn: 'You use access to save photos to KDrama Chat',
  NSPhotoLibraryUsageDescriptionEn: 'You can attach photos and GIFs from your albums to messages or as profile pictures',
  consoleLogo: `
██╗  ██╗██████╗ ██████╗  █████╗ ███╗   ███╗ █████╗        ██████╗██╗  ██╗ █████╗ ████████╗
██║ ██╔╝██╔══██╗██╔══██╗██╔══██╗████╗ ████║██╔══██╗      ██╔════╝██║  ██║██╔══██╗╚══██╔══╝
█████╔╝ ██║  ██║██████╔╝███████║██╔████╔██║███████║█████╗██║     ███████║███████║   ██║   
██╔═██╗ ██║  ██║██╔══██╗██╔══██║██║╚██╔╝██║██╔══██║╚════╝██║     ██╔══██║██╔══██║   ██║   
██║  ██╗██████╔╝██║  ██║██║  ██║██║ ╚═╝ ██║██║  ██║      ╚██████╗██║  ██║██║  ██║   ██║   
╚═╝  ╚═╝╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝╚═╝  ╚═╝       ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   
`
};
