import { KdramaChatProjectMeta } from '@pixels/universal/model/apps/project-meta/kdrama-chat-project-meta';
import { EnvironmentsConfig } from '@pixels/universal/model/firebase/environment.model';
import { Phase } from '@pixels/universal/model/phase-model';
import { PROD_PROXY_URL } from '@pixels/universal/model/proxy/proxy.model';

export const environment: EnvironmentsConfig = {
  production: true,
  apiUrl: KdramaChatProjectMeta.prod?.apiUrl ?? '',
  phase: Phase.prod,
  firebase: KdramaChatProjectMeta.prod?.firebaseConfig ?? {},
  talkPlusAppId: KdramaChatProjectMeta.prod?.talkPlusAppId ?? '',
  vapidKey: KdramaChatProjectMeta.prod?.webPushKey ?? '',
  useEmulators: false,
  proxyUrl: PROD_PROXY_URL
};
